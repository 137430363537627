import axios from 'axios';


const Api = axios.create({
    baseURL: process.env.VUE_APP_API,
});
Api.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
Api.interceptors.request.use(function (config) {
    return config;
  }, function (error) {
    return Promise.reject(error);
});

export default Api;

/**
 * =================================================
 * LOGIN
 * =================================================
 */

export function setToken(id: string) {
    localStorage.setItem('userToken',id);
}

export function setJWT(jwt: string) {
    Api.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
}
  
export function clearJWT() {
    delete Api.defaults.headers.common['Authorization'];
}


/**
 * =================================================
 * ROLES
 * =================================================
 */
