




































import { Component, Vue } from 'vue-property-decorator';
import  Api, { setToken } from '../../router/api';
import axios from 'axios';

@Component({})
export default class Login extends Vue {
    public email = '';
    public password = '';
    public loading = false;
    public error = false;
 
    public async makeLogin(){
        this.error = false
        if (this.email!=='' && this.password !== '')
        {
            this.loading = true;
            const resp = await axios.post(process.env.VUE_APP_API+"auth",{
                email: this.email,
                password: this.password,
            },{headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json"
      }}).then((data) => data.data).catch(() => this.error = true);    
            if (resp !== undefined && resp.user){
                setToken(resp.user._id)
                window.location.href = '/control/admin'
            }
            this.loading = false;
        }
        return false;
    }

}
